var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mew-component--partners py-7 py-md-12 mb-6" },
    [
      _c("v-sheet", { attrs: { color: "transparent", align: "center" } }, [
        _c(
          "div",
          { staticClass: "mew-heading-3 text-uppercase textMedium--text mb-1" },
          [_vm._v(" " + _vm._s(_vm.$t("aboutUs.partners")) + " ")]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-2 ml-10 px-4", attrs: { align: "center" } },
        _vm._l(_vm.partners, function (p, key) {
          return _c(
            "v-col",
            { key: key, attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
            [
              p.link
                ? _c("a", { attrs: { href: p.link, target: "_blank" } }, [
                    _c("img", {
                      staticStyle: { width: "90%", "max-width": "120px" },
                      attrs: { src: p.img },
                    }),
                  ])
                : _c("img", {
                    staticStyle: { width: "90%", "max-width": "120px" },
                    attrs: { src: p.img },
                  }),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }